(<template>
  <footer class="logout-footer">
    <div class="logout-footer__info-row">
      <div class="logout-footer__wrapper">
        <div class="logout-footer__content-col logout-footer__content-col--centered logout-footer__content-col--logo">
          <router-link :to="$makeRoute({name: 'SignIn'})"
                       class="logout-footer__logo" />
        </div>
        <div v-if="!iosApp && androidAppLink"
             class="logout-footer__content-col logout-footer__content-col--centered logout-footer__content-col--android">
          <a :href="androidAppLink"
             class="logout-footer__app-link logout-footer__app-link--android"></a>
        </div>
        <div v-if="iosAppLink"
             class="logout-footer__content-col logout-footer__content-col--centered logout-footer__content-col--ios">
          <a :href="iosAppLink"
             class="logout-footer__app-link logout-footer__app-link--ios"></a>
        </div>
        <div class="logout-footer__content-col logout-footer__content-col--centered logout-footer__content-col--phone">
          <a :href="`tel:${supportPhoneText}`"
             class="logout-footer__call-link js-copy-to-clipboard">{{ supportPhoneText }}</a>
        </div>
      </div>
    </div>
    <div class="logout-footer__wrapper logout-footer__wrapper--sitemap">
      <div class="logout-footer__content-col">
        <locale-select class="logout-footer__lang-select" />
        <div class="logout-footer__social-links-cont">
          <a target="_blank"
             :href="twitterLink"
             class="logout-footer__social-link logout-footer__social-link--twitter"></a>
          <a target="_blank"
             :href="facebookLink"
             class="logout-footer__social-link logout-footer__social-link--facebook"></a>
          <a target="_blank"
             :href="linkedinLink"
             class="logout-footer__social-link logout-footer__social-link--linkedin"></a>
        </div>
      </div>
      <div v-if="certificate"
           class="logout-footer__content-col">
        <div class="logout-footer__ico-cont">
          <div :class="[`logout-footer__ico-img--${$language.current}`]"
               class="logout-footer__ico-img"></div>
          <p class="logout-footer__ico-text">{{ certificate }}</p>
        </div>
      </div>
    </div>
    <div class="logout-footer__wrapper logout-footer__wrapper--copyright">
      <div class="logout-footer__bottom-row">
        <nav class="logout-footer__bottom-nav">
          <a :href="termsLink"
             class="logout-footer__bottom-nav-link"
             target="_blank">{{ $gettext('Terms of Service') }}</a>
          <a :href="privacyLink"
             class="logout-footer__bottom-nav-link"
             target="_blank">{{ $gettext('Privacy Policy') }}</a>
          <a v-if="supportPortalLink"
             :href="supportPortalLink"
             class="logout-footer__bottom-nav-link"
             target="_blank">{{ $gettext('Support') }}</a>
        </nav>
        <p class="logout-footer__copy-text">{{ copyright }}, {{ address }}</p>
      </div>
    </div>
  </footer>
</template>)

<script>
  export default {
    data() {
      return {
        androidAppLink: this.$getString('app', 'androidAppLink'),
        iosAppLink: this.$getString('app', 'iosAppLink'),
        supportPhoneText: this.$getString('domainData', 'supportPhoneText'),
        supportPhone: this.$getString('domainData', 'supportPhone'),
        facebookLink: this.$getString('social', 'facebookLink'),
        twitterLink: this.$getString('social', 'twitterLink'),
        linkedinLink: this.$getString('social', 'linkedinLink'),
        supportPortalLink: this.$getString('domainData', 'supportPortalLink')
      };
    },
    computed: {
      iosApp() { return this.$store.state.mobileApp == 'ios'; },
      copyright() { return this.$getString('footer', 'copyright'); },
      address() { return this.$getString('footer', 'address'); },
      certificate() { return this.$getString('footer', 'certificate'); },
      privacyLink() { return this.$getString('domainData', 'privacyLink'); },
      termsLink() { return this.$getString('domainData', 'termLink'); },
    }
  };
</script>

<style>
  .logout-footer__lang-select .sk-select__typing-input {
    background-color: #000;
  }

  .logout-footer__lang-select .sk-select__arrow {
    background-color: #000;
  }

  .logout-footer__lang-select .sk-select__arrow::before {
    background-color: #fff;
  }

  .logout-footer__lang-select .sk-select__arrow::after {
    background-color: #fff;
  }

  .logout-footer__lang-select .sk-select__list {
    top: auto;
    bottom: calc(100% + 2px);
    border: 1px solid #fff;
    border-bottom: 1px solid transparent;
    background-color: #000;
  }

  .logout-footer__lang-select .sk-select__list-item:hover {
    background-color: #f4f5f7;
    color: #000;
  }

  .logout-footer__lang-select .sk-select {
    border: 1px solid #fff;
    caret-color: #fff;
  }

  @media (max-width: 767px) {
    .logout-footer__lang-select .sk-select__value {
      padding: 0 10px;
    }
  }
</style>

<style scoped>
  .logout-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 20px 45px;
    background-color: #000;
    background-color: var(--logout-footer-color);
  }

  .logout-footer__info-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 45px;
    padding: 35px 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  }

  .logout-footer__wrapper {
    display: flex;
    justify-content: space-between;
    width: 1140px;
    max-width: 100%;
  }

  .logout-footer__content-col {
    display: block;
    width: 240px;
    max-width: 25%;
    padding-right: 5px;
  }

  .logout-footer__content-col--centered {
    align-self: center;
  }

  .logout-footer__logo {
    display: block;
    width: 150px;
    height: 26px;
    background-image: url(~@assets/imgs/logos/white_logo.svg);
    background-position: 0 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .logout-footer__app-link {
    display: block;
    width: 122px;
    height: 36px;
    background-position: 0 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .logout-footer__app-link:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .logout-footer__app-link:hover {
    box-shadow: none;
    opacity: 0.6;
  }

  .logout-footer__app-link:active {
    box-shadow: none;
    opacity: 1;
  }

  .logout-footer__app-link--android {
    background-image: url(~@assets/imgs/undefined_imgs/android_app_img.png);
  }

  .logout-footer__app-link--ios {
    background-image: url(~@assets/imgs/undefined_imgs/ios_app_img.svg);
  }

  .logout-footer__call-link {
    display: inline-block;
    width: auto;
    height: 25px;
    padding-left: 40px;
    background-image: url(~@assets/imgs/undefined_imgs/phone-icon-footer.svg);
    background-position: 0 50%;
    background-size: auto 25px;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 18px;
    line-height: 25px;
  }

  .logout-footer__call-link:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .logout-footer__call-link:hover {
    box-shadow: none;
    opacity: 0.6;
  }

  .logout-footer__call-link:active {
    box-shadow: none;
    opacity: 1;
  }

  .logout-footer__lang-select {
    width: 170px;
    color: #fff;
  }

  .logout-footer__social-links-cont {
    display: flex;
    justify-content: space-between;
    width: 170px;
    margin-top: 45px;
  }

  .logout-footer__social-link {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .logout-footer__social-link:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .logout-footer__social-link:hover {
    box-shadow: none;
    opacity: 0.6;
  }

  .logout-footer__social-link:active {
    box-shadow: none;
    opacity: 1;
  }

  .logout-footer__social-link--twitter {
    background-image: url(~@assets/imgs/social_links_icons/f-twitter-icon-footer.svg);
  }

  .logout-footer__social-link--facebook {
    background-image: url(~@assets/imgs/social_links_icons/f-facebook-icon-footer.svg);
  }

  .logout-footer__social-link--linkedin {
    background-image: url(~@assets/imgs/social_links_icons/f-linkedin-icon-footer.svg);
  }

  .logout-footer__ico-cont {
    display: block;
    width: 100%;
  }

  .logout-footer__ico-img {
    display: block;
    width: 200px;
    height: 81px;
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .logout-footer__ico-img--en {
    background-image: url(~@assets/imgs/undefined_imgs/footer_ico_en.png);
  }

  .logout-footer__ico-img--nb {
    background-image: url(~@assets/imgs/undefined_imgs/footer_ico_no.png);
  }

  .logout-footer__ico-text {
    color: #fff;
    font-size: 9px;
    line-height: 2.44;
  }

  .logout-footer__bottom-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    color: #fff;
  }

  .logout-footer__bottom-nav {
    display: flex;
    text-decoration: underline;
  }

  .logout-footer__bottom-nav-link {
    margin-right: 30px;
  }

  .logout-footer__copy-text {
    display: inline-block;
    text-align: right;
  }

  @media (max-width: 1100px) {
    .logout-footer__bottom-row {
      flex-direction: column;
      align-items: center;
    }

    .logout-footer__bottom-nav {
      margin-bottom: 25px;
      text-align: center;
    }

    .logout-footer__bottom-nav-link:last-child {
      margin-right: 0;
    }

    .logout-footer__copy-text {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    .logout-footer__wrapper {
      flex-wrap: wrap;
    }

    .logout-footer__content-col {
      width: 50%;
      max-width: initial;
    }

    .logout-footer__content-col:first-child,
    .logout-footer__content-col:nth-child(2) {
      margin-bottom: 30px;
    }

    .logout-footer__content-col--logo {
      order: 1;
    }

    .logout-footer__content-col--android.logout-footer__content-col--android {
      order: 3;
      margin-bottom: 0;
    }

    .logout-footer__content-col--ios {
      order: 4;
    }

    .logout-footer__content-col--phone.logout-footer__content-col--phone {
      order: 2;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 520px) {
    .logout-footer__content-col {
      width: 100%;
    }

    .logout-footer__content-col:not(:last-child) {
      margin-bottom: 20px;
    }

    .logout-footer__content-col--android.logout-footer__content-col--android,
    .logout-footer__content-col--ios.logout-footer__content-col--ios {
      width: 50%;
      margin-bottom: 20px;
    }

    .logout-footer__content-col--android.logout-footer__content-col--android {
      order: 2;
    }

    .logout-footer__content-col--ios {
      order: 3;
    }

    .logout-footer__content-col--phone.logout-footer__content-col--phone {
      order: 4;
      margin-bottom: 0;
    }

    .logout-footer__bottom-nav {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .logout-footer__bottom-nav-link {
      width: 50%;
      margin-right: 0;
    }

    .logout-footer__bottom-nav-link:first-child,
    .logout-footer__bottom-nav-link:nth-child(2) {
      margin-bottom: 20px;
    }
  }
</style>
