(<template>
  <div class="landing-wrapper">
    <landing-header @mobilenav="catchMobileNav" />
    <template v-if="!mobileNav">
      <slot></slot>
      <logout-footer />
    </template>
  </div>
</template>)

<script>
  import LandingHeader from '@/components/landing/LandingHeader';
  import LogoutFooter from '@/components/shared_components/LogoutFooter';

  export default {
    components: {
      'logout-footer': LogoutFooter,
      'landing-header': LandingHeader
    },
    data() {
      return {
        mobileNav: false,
        oldScroll: 0
      };
    },
    methods: {
      catchMobileNav(state) {
        if (state) {
          this.oldScroll = window.pageYOffset || document.documentElement.scrollTop;
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        } else {
          setTimeout(() => {
            document.documentElement.scrollTop = this.oldScroll;
            document.body.scrollTop = this.oldScroll;
            this.oldScroll = '';
          }, 0);
        }
        this.mobileNav = state;
      }
    }
  };
</script>

<style scoped>
  .landing-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 60px;
  }

  @media (max-width: 1024px) {
    .landing-wrapper {
      padding-top: 45px;
    }
  }
</style>
